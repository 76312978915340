export interface Api {
    url: string;
    method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
}

export interface AuthToken {
    token: string;
    expiry: Date;
}

export enum ServiceStatus {
    OK = 'OK',
    ERROR = 'ERROR',
    WARNING = 'WARNING'
}

export enum EmailCheckErrorType {
    EMAIL_ALREADY_REGISTERED = 'EMAIL_ALREADY_REGISTERED',
    NOT_BUSINESS_EMAIL = 'NOT_BUSINESS_EMAIL',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export interface EmailValidationResult {
    message: string;
    code: ServiceStatus;
    errorType?: EmailCheckErrorType;
}

export interface RegisterUserRequest {
    emailId: string;
    otpId: string;
    otp: string;
    password: string;
    firstName: string;
    lastName: string;
}

export interface ApiGenericResponse {
    message: string;
    code: ServiceStatus;
}

export enum UIOperationCode {
    NONE = 'NONE',
    SHOW_VERIFY_DOMAIN_OWNER_PANEL = 'SHOW_VERIFY_DOMAIN_OWNER_PANEL'
}

export interface UserLicense {
    [key: string]: string[];
}
export interface MyInfo {
    userId: string;
    userName: string;
    firstName: string;
    lastName: string;
    emailId: string;
    isOwner: boolean;
    domain: string;
    manager: string;
    domainVerification: {
        message: string;
        operationCode: UIOperationCode;
    };
    domainName: string;
    businessId: string;
    licenseAndScope: UserLicense;
    isPortalUser: boolean;
    isPortalAdmin: boolean;
    location: Location;
}

export interface User {
    firstName: string;
    lastName: string;
    id: string;
    userName: string;
    emailId: string;
    manager: string;
    isOwner: boolean;
    isActive: boolean;
}

export interface UserPatchParam {
    firstName?: string;
    lastName?: string;
    managerId?: string;
    departmentId?: string;
    designationId?: string;
    locationId?: string;
}

export interface UserDetails extends User {
    managerDetails?: UserDetails;
    department?: Department;
    designation?: Designation;
    location?: Location;
}

export interface AuthorizationGroupPublicDetails {
    groupId: string;
    groupName: string;
    tenantId: string;
    businessName: string;
}

export interface AuthorizationGroup {
    groupId: string;
    groupName: string;
    creator: string;
    domainId: string;
    canEdit?: boolean;
    isPublicUrlActive: boolean;
    publicUrl: string;
    embedPublicUrl: string;
    approvalRequiredForPublicUrlSignup: boolean;
    pendingRequestCount: number;
}

export interface AuthorizationGroupDetails extends AuthorizationGroup {
    owner: string[];
    licenseScopes: string[];
}

export interface Scope {
    name: string;
    description: string;
    id: string;
}
export interface License {
    id: string;
    name: string;
    description: string;
    scopes: Scope[];
}

export interface Tenant {
    domain: string;
    domainId: string;
    businessName: string;
    businessId?: string;
    isActive?: boolean;
}

export interface RegisterAdminUserFromPublicEmailDomain {
    emailId: string;
    firstName: string;
    lastName: string;
    businessName: string;
    businessId: string;
}

export interface JoinAuthGroupRequest {
    groupId: string;
    emailId: string;
    firstName: string;
    lastName: string;
}

export interface LicenseBasic {
    id: string;
    name: string;
    description: string;
    icon?: string;
}

export interface Department {
    departmentId: string;
    name: string;
    description: string;
    active: boolean;
    domainId: string;
}

export interface Designation {
    name: string;
    description: string;
    designationId: string;
    active: boolean;
    domainId: string;
    level: number;
}

export interface LevelSuggestions {
    level: number;
    sameLevelDesignations: Designation[];
    lowerLevelDesignations: Designation[];
    higherLevelDesignations: Designation[];
}

export interface LocationUpdateRequest {
    name?: string;
    address?: string;
    email?: string;
    phone?: string;
    website?: string;
    isActive?: boolean;
}

export interface LocationRequest extends LocationUpdateRequest {
    name: string;
    city?: string;
    state?: string;
    pin?: string;
    country?: string;
    gstinNo?: string;
}

export interface Location extends LocationRequest {
    id: string;
    isActive: boolean;
}

export enum AccountType {
    SUPPLIER = 'supplier',
    CUSTOMER = 'customer'
}

export interface ContactRequest {
    name: string;
    phone?: string;
    emailId?: string;
}

export interface Contact extends ContactRequest {
    contactId: string;
}

export interface AccountRequest {
    name: string;
    address?: string;
    emailId?: string;
    phone: string;
    website?: string;
    city?: string;
    state?: string;
    pincode?: string;
    gstinNo?: string;
    panNo?: string;
    tanNo?: string;
    cinNo?: string;
    notes?: string;
}

export interface Account extends AccountRequest {
    accountId: string;
    type: AccountType;
    contactsCount: number;
}

export interface ServiceRequest {
    name: string;
    description: string;
    sellingPrice: number;
    categoryId?: string;
    sacCode?: string;
    gstRate?: number;
}

export interface Service extends ServiceRequest {
    sku: number;
    serviceId: string;
    isActive: boolean;
}

export interface ItemRequest {
    name: string;
    description?: string;
    sellingPrice: number;
    categoryId?: string;
    brand?: string;
    manufacturer?: string;
    hsnCode?: string;
    gstRate: number;
}

export interface Item extends ItemRequest {
    itemId: string;
    sku: number;
    isActive: boolean;
}

export interface MoveStock {
    sku: number;
    quantity: number;
    fromLocationId: string;
    toLocationId: string;
    reason: string;
}

export interface QuantityCorrection {
    sku: number;
    quantity: number;
    locationId: string;
    reason: string;
}

export interface ItemStock {
    name: string;
    sellingPrice: number;
    sku: number;
    quantity: number;
    hsnCode?: string;
    gstRate: number;
}

export interface Stock {
    sku: number;
    quantity: number;
}

export interface ReceiveSupplyItem {
    sku: number;
    quantity: number;
    itemPrice: number;
}
export interface ReceiveSupplyRequest {
    items: ReceiveSupplyItem[];
    locationId: string;
    supplierId: string;
    invoiceDate?: Date;
    invoiceNumber: string;
    discountAmount?: number;
}

export interface ReceiveSupply {
    purchaseReceiptId: string;
    invoiceDate?: Date;
    invoiceNumber: string;
    discountAmount?: number;
    location: Location;
    supplier: Account;
    items: {
        item: Item;
        quantity: number;
        itemPrice: number;
    }[];
}

export interface PurchaseReceiptBasic {
    purchaseReceiptId: string;
    invoiceDate: Date;
    invoiceNumber: string;
}

export interface Config {
    app: string;
    key: string;
    value: string;
}

export interface OrderRequestItem {
    sku: number;
    quantity: number;
    price: number;
}
export interface OrderRequest {
    invoiceDate: Date;
    customerAccountId: string;
    locationId: string;
    discount: number;
    items: OrderRequestItem[];
    emi?: {
        downPayment: number;
        emiSchedule: EmiScheduleInstance[];
    };
}

export interface OrderItem {
    item: Item;
    quantity: number;
    price: number;
}

export interface EMIInstance {
    emiDate: Date;
    emiAmount: number;
    emiScheduleId: string;
    emiAmountPaid: number;
}

export interface Order {
    orderId: string;
    invoiceDate: Date;
    invoiceNumber: string;
    customer: Account;
    location: Location;
    discount: number;
    items: OrderItem[];
    orderPlacedBy: User;
    orderPlacedAt: Date;
    emiDetails: EMIInstance[];
    downPayment: number;
}

export interface OrderFilter {
    locationId?: string;
    customerId?: string;
    userId?: string;
}

export interface OrderBasic {
    orderId: string;
    invoiceNo: number;
    invoiceDate: Date;
}

export enum Frequency {
    Weekly = 'Weekly',
    Monthly = 'Monthly'
}

export interface EmiScheduleInstance {
    date: Date;
    amount: number;
}

export interface ProjectRequest {
  name: string;
  description: string;
}

export interface Project extends ProjectRequest {
  projectId: string;
  addedBy: string;
  generationStatus?: string;
  lastGenerationTime?: string;
  videoUrl?: string;
  logUrl?: string;
  progressMessage?: string;
  backgroundMusicId?: string;
}

export interface ProjectContentRequest {
  projectId: string;
  content: string;
}

export interface ProjectContent extends ProjectContentRequest {
  textId: string;
  isAudioReady: boolean;
  orderNo: number;
  imageUrl?: string;
  voice: 'M' | 'F';
}

export interface ExplisoftService {
    login(userName: string, password: string): Promise<AuthToken>;
    renewToken(): Promise<AuthToken>;
    validateEmail(emailId: string): Promise<EmailValidationResult>;
    generateOTP(emailId: string): Promise<{ otpId: string }>;
    registerUser(registerUserRequest: RegisterUserRequest): Promise<ApiGenericResponse>;
    me(): Promise<MyInfo>;
    checkBusinessIdAvailability(businessId: string): Promise<{ available: boolean }>;
    addDomainBusinessId(businessId: string): Promise<boolean>;
    addUserInDomain(userName: string, firstName: string, lastName: string, emailId: string, manager?: string): Promise<{ message: string }>;
    getFilteredUser(query: string, showInactive?: boolean): Promise<User[]>;
    createAuthorizationGroup(name: string): Promise<AuthorizationGroupDetails>;
    searchAuthGroup(groupName: string, owner: string, scope: string): Promise<AuthorizationGroup[]>;
    getAuthorizationGroupDetails(groupId: string): Promise<AuthorizationGroupDetails>;
    getAuthorizationGroupUsers(groupId: string): Promise<User[]>;
    addUserInAuthorizationGroup(groupId: string, userId: string): Promise<void>;
    removeUserFromAuthorizationGroup(groupId: string, userId: string): Promise<void>;
    getUserDetails(userId: string, withManagerInfo?: boolean, withMetaInfo?: boolean): Promise<UserDetails>;
    getAvailableLicense(): Promise<License[]>;
    saveAuthGroup(groupId: string, groupName: string, owners: string[], scopes: string[]): Promise<AuthorizationGroupDetails>;
    forgotPassword(username: string): Promise<{ message: string }>;
    resetPassword(username: string, token: string): Promise<{ message: string }>;
    searchTenant(query: string): Promise<Tenant[]>;
    enableTenant(tenantId: string): Promise<void>;
    disableTenant(tenantId: string): Promise<void>;
    getTenantDetails(tenantId: string): Promise<Tenant>;
    forgotUsername(emailId: string): Promise<{ message: string }>;
    registerAdminUserFromPublicEmailDomain(request: RegisterAdminUserFromPublicEmailDomain): Promise<{ message: string }>;
    setPublicUrlSignup(groupId: string, enable: boolean): Promise<{ message: string }>;
    setPublicUrlSignupApprovalRequired(groupId: string, enable: boolean): Promise<{ message: string }>;
    getAuthorizationGroupPublicDetails(groupId: string): Promise<AuthorizationGroupPublicDetails>;
    joinAuthorizationGroupUsingPublicLink(
        groupId: string,
        emailId: string,
        firstName: string,
        lastName: string
    ): Promise<{ message: string }>;
    getAuthorizationGroupJoinRequests(groupId: string): Promise<JoinAuthGroupRequest[]>;
    approveOrRejectAuthorizationGroupJoinRequest(request: JoinAuthGroupRequest, approve: boolean): Promise<{ message: string }>;
    availableToInstall(): Promise<LicenseBasic[]>;
    installApp(appId: string): Promise<{ message: string }>;
    uninstallApp(appId: string): Promise<{ message: string }>;
    installedApps(): Promise<LicenseBasic[]>;

    createDepartment(name: string, description: string): Promise<Department>;
    getDepartments(includingInActive?: boolean): Promise<Department[]>;
    changeDepartmentActiveState(department: Department, active: boolean): Promise<Department>;
    updateDepartment(department: Department): Promise<Department>;

    createDesignation(name: string, description: string, level: number): Promise<Designation>;
    getDesignations(includingInActive?: boolean): Promise<Designation[]>;
    changeDesignationActiveState(designation: Designation, active: boolean): Promise<Designation>;
    updateDesignation(designation: Designation): Promise<Designation>;
    getLevelSuggestions(level: number): Promise<LevelSuggestions>;
    updateUserDetails(userId: string, patchParam: UserPatchParam): Promise<{ message: string }>;
    changeUserActiveState(userId: string, active: boolean): Promise<{ message: string }>;

    createLocation(location: LocationRequest): Promise<Location>;
    getLocation(locationId: string): Promise<Location>;
    updateLocation(locationId: string, location: LocationUpdateRequest): Promise<Location>;
    getLocations(includingInActive: boolean): Promise<Location[]>;
    changeLocationActiveState(locationId: string, active: boolean): Promise<Location>;
    addUserToLocation(locationId: string, userId: string): Promise<{ message: string }>;
    getUserLocation(userId: string): Promise<Location>;

    createAccount(accountType: AccountType, account: AccountRequest): Promise<Account>;
    getAccounts(accountType: AccountType, query: string, page?: number): Promise<Account[]>;
    updateAccount(accountType: AccountType, accountId: string, account: AccountRequest): Promise<Account>;

    getContacts(accountType: AccountType, accountId: string): Promise<Contact[]>;
    createContact(accountType: AccountType, accountId: string, contact: ContactRequest): Promise<Contact>;
    updateContact(accountType: AccountType, accountId: string, contactId: string, contact: ContactRequest): Promise<Contact>;

    createItem(item: ItemRequest): Promise<Item>;
    getItems(query: string, page?: number): Promise<{ hasMore: boolean; items: Item[] }>;
    updateItem(sku: number, item: ItemRequest): Promise<Item>;
    enableDisableItem(sku: number, enable: boolean): Promise<Item>;
    getAllItemsStockByLocation(locationId: string, query: string, page: number): Promise<{ hasMore: boolean; items: ItemStock[] }>;
    correctStockByLocation(correction: QuantityCorrection): Promise<ItemStock>;
    moveStock(moveStock: MoveStock): Promise<Stock>;
    receiveSupply(receiveSupply: ReceiveSupplyRequest): Promise<ReceiveSupply>;
    getAllPurchaseReceipts(
        param: { locationId?: string; supplierId?: string },
        page: number
    ): Promise<{ hasMore: boolean; items: PurchaseReceiptBasic[] }>;
    getPurchaseReceipt(purchaseReceiptId: string): Promise<ReceiveSupply>;

    setConfig(app: string, key: string, value: string): Promise<Config>;
    getConfig(app: string, key: string): Promise<Config>;

    createOrder: (order: OrderRequest) => Promise<Order>;
    getOrder: (orderId: string) => Promise<Order>;
    searchOrders: (filter: OrderFilter, pageNo: number) => Promise<{ data: OrderBasic[]; hasMore: boolean }>;
    emiOverdue: (days: number) => Promise<{ data: OrderBasic[] }>;
    receiveEMIPayment(
        emiScheduleId: string,
        amount: number,
        paymentDate: Date,
        paymentMode: string,
        paymentReference: string
    ): Promise<{ message: string }>;

    createProject: (project: ProjectRequest) => Promise<Project>;
    getProjects: (query: string, pageNo: number) => Promise<{ hasMore: boolean; projects: Project[] }>;
    getProjectDetails: (projectId: string) => Promise<Project>;
    updateProjectDetails: (projectId: string, project: ProjectRequest) => Promise<Project>;
    deleteProject: (projectId: string) => Promise<void>;

    getProjectContents(projectId: string): Promise<ProjectContent[]>;
    createProjectContent: (projectId: string, content: string) => Promise<ProjectContent>;
    updateProjectContent: (projectId: string, contentId: string, content: string, voice: 'M' | 'F') => Promise<ProjectContent>;
    deleteProjectContent: (projectId: string, contentId: string) => Promise<void>;
    uploadImage: (projectId: string, contentId: string, file: File) => Promise<{imageUrl: string}>;
    generateVideo(projectId: string): Promise<{message: string}>;
    updateProjectBackgroundMusic(projectId: string, musicId: string): Promise<void>;

    addTenantVideoQuota(tenantId: string, quota: number): Promise<{message: string}>;
    getTenantVideoQuota(tenantId: string): Promise<{quota: number}>;

    uploadMusic: (name: string, file: File) => Promise<{musicUrl: string}>;
    getMusicList: () => Promise<{musicList: {name: string, url: string}[]}>;
}
