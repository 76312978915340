import React from 'react';
import { useGetBackgroundMusic } from "@hooks";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export const MusicSelector = (props: {
    onSelect?: (music: string | null) => void;
    selected?: string;
}) => {
    const { onSelect, selected } = props;
    const { backgroundMusics } = useGetBackgroundMusic();
    const backgroundMusicsWithNone = React.useMemo(() => {
        return [{ name: 'None', url: '' }, ...backgroundMusics];
    }, [backgroundMusics]);

    const url = React.useMemo(() => {
        return backgroundMusics.find((music) => music.name === selected)?.url || '';
    }, [selected, backgroundMusics]);
    const audioRef = React.useRef<HTMLAudioElement>(null);
    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = url;
        }
    }, [url]);

    const handleMusicSelectionChange = React.useCallback((event: SelectChangeEvent) => {
        onSelect?.(event.target.value === 'None' ? null : event.target.value);
    }, [onSelect]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '3px', justifyContent: 'space-between' }}>
            <Select
                value={props?.selected || 'None'}
                label="Music"
                onChange={handleMusicSelectionChange}
                style={{ minWidth: '250px' }}
            >
                {
                    backgroundMusicsWithNone.map((music) => {
                        return <MenuItem key={music.name} value={music.name}>{music.name}</MenuItem>;
                    })
                }
            </Select>
            <audio ref={audioRef} controls />
        </div>
    );
}