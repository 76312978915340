import React from 'react';
import { explisoftService, error as errorToast, ProjectRequest, Project } from '@services';

export const useTextImageToVideoProjects = (query?: string) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [hasMore, setHasMore] = React.useState<boolean>(false);
    const [projects, setProjects] = React.useState<Project[]>([]);
    const [page, setPage] = React.useState<number>(0);

    const fetchProjects = React.useCallback(
        async (page = 0) => {
            if (query === undefined) {
                return;
            }
            setIsLoading(true);
            setError('');
            try {
                try {
                    const nextProjects = await explisoftService.getProjects(query, page);
                    setPage(page);
                    setHasMore(nextProjects.hasMore);
                    if (page === 0) {
                        setProjects(nextProjects.projects);
                    } else {
                        setProjects((prevProjects) => [...prevProjects, ...nextProjects.projects]);
                    }
                    return nextProjects;
                } catch (err) {
                    setError(err.message || 'Something went wrong');
                    errorToast(err.message || 'Something went wrong');
                    throw err;
                }
            } finally {
                setIsLoading(false);
            }
        },
        [query]
    );

    React.useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const loadMore = () => {
        if (hasMore) {
            fetchProjects(page + 1);
        }
    };

    const createProject = async (project: ProjectRequest) => {
        setIsLoading(true);
        setError('');
        explisoftService.createProject(project)
            .then(() => {
                fetchProjects();
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const deleteProject = async (projectId: string) => {
        setIsLoading(true);
        setError('');
        explisoftService.deleteProject(projectId)
            .then(() => {
                setProjects((prevProjects) => prevProjects.filter((project) => project.projectId !== projectId));
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const refetch = () => {
        fetchProjects();
    }

    return { isLoading, error, projects, loadMore, createProject, hasMore, deleteProject, refetch };
}