import React from 'react';
import { explisoftService, error as errorToast, success as successToast } from '@services';

export const useGetBackgroundMusic = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [backgroundMusics, setBackgroundMusics] = React.useState<{name: string, url: string}[]>([]);

    React.useEffect(() => {
        setIsLoading(true);
        setError('');
        explisoftService.getMusicList()
            .then((backgroundMusics) => {
                setBackgroundMusics(backgroundMusics.musicList);
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                setBackgroundMusics([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return { isLoading, error, backgroundMusics };
}

export const useUploadMusic = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [success, setSuccess] = React.useState<boolean>(false);

    const uploadMusic = async (name: string, file: File) => {
        setIsLoading(true);
        setError('');
        setSuccess(false);
        return explisoftService.uploadMusic(name, file)
            .then(() => {
                setSuccess(true);
                successToast('Music uploaded successfully');
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
                throw err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return { isLoading, error, success, uploadMusic };
}