import { ProjectContent, explisoftService, error as errorToast, success, Project } from '@services';
import React from 'react';

export const useVideoProjectDetails = (projectId: string, shouldPeriodicRefresh?: boolean) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [contents, setContents] = React.useState<ProjectContent[]>([]);

    const [project, setProject] = React.useState<Project | null>(null);
    React.useEffect(() => {
        explisoftService.getProjectDetails(projectId)
            .then((project) => {
                setProject(project);
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
            });
        if (!shouldPeriodicRefresh) {
            return;
        }
        const intervalId = setInterval(() => {
            explisoftService.getProjectDetails(projectId)
                .then((project) => {
                    // set project only if it has changed
                    setProject((prevProject) => {
                        if (prevProject?.generationStatus !== project.generationStatus 
                            || prevProject?.backgroundMusicId !== project.backgroundMusicId 
                            || prevProject?.videoUrl !== project.videoUrl
                            || prevProject?.logUrl !== project.logUrl
                            || prevProject?.progressMessage !== project.progressMessage
                            || prevProject?.lastGenerationTime !== project.lastGenerationTime
                            
                        ) {
                            return project;
                        }
                        return prevProject;
                    });
                })
                .catch((err) => {
                    setError(err.message || 'Something went wrong');
                    errorToast(err.message || 'Something went wrong');
                });
        }, 30 * 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [projectId, shouldPeriodicRefresh]);

    React.useEffect(() => {
        setIsLoading(true);
        setError('');
        explisoftService.getProjectContents(projectId)
            .then((contents) => {
                setContents(contents);
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong')
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [projectId]);

    const generateVideo = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        explisoftService.generateVideo(projectId)
            .then((data) => {
                success(data.message || 'Video generation submitted successfully');
                setProject((prevProject) => {
                    return { ...prevProject, generationStatus: 'IN_PROGRESS' };
                });
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [projectId]);

    const createContent = React.useCallback(async () => {
        setIsLoading(true);
        setError('');
        explisoftService.createProjectContent(projectId, '')
            .then((content) => {
                setContents((prevContents) => [...prevContents, content]);
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [projectId]);

    const handleDelete = React.useCallback(async (contentId: string) => {
        setIsLoading(true);
        setError('');
        return explisoftService.deleteProjectContent(projectId, contentId)
            .then(() => {
                setContents((prevContents) => {
                    const filteredContent = [];
                    for (const content of prevContents) {
                        if (content.textId !== contentId) {
                            filteredContent.push(content);
                        }
                    }
                    return filteredContent;
                });
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [projectId]);

    const handleUpdate = React.useCallback(async (contentId: string, text: string, voice: 'M' | 'F') => {
        setIsLoading(true);
        setError('');
        return explisoftService.updateProjectContent(projectId, contentId, text, voice)
            .then((content) => {
                setContents((prevContents) => prevContents.map(c => c.textId === contentId ? content : c));
            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [projectId]);

    const updateProjectBackgroundMusic = React.useCallback(async (musicId: string) => {
        setIsLoading(true);
        setError('');
        return explisoftService.updateProjectBackgroundMusic(projectId, musicId)
            .then(() => {

            })
            .catch((err) => {
                setError(err.message || 'Something went wrong');
                errorToast(err.message || 'Something went wrong');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [projectId]);

    return { isLoading, error, contents, createContent, handleDelete, handleUpdate, generateVideo, project, updateProjectBackgroundMusic };
};