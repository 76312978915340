import React from 'react';
import { explisoftService, User } from '@services';

export const useGetUsers = (query: string, showInactive = false) => {
    const [users, setUsers] = React.useState<User[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');

    React.useEffect(() => {
        setLoading(true);
        explisoftService
            .getFilteredUser(query, showInactive)
            .then((users) => {
                setUsers(users);
                setError('');
            })
            .catch((error) => {
                setError(error.message);
                setUsers([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [query, showInactive]);

    return {
        users,
        loading,
        error
    };
};
