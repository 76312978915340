export enum FeatureFlags {
    enablePowerBarSearch = 'enablePowerBarSearch',
}

const featureFlagsDefaultValue = new Map<FeatureFlags, boolean>();

featureFlagsDefaultValue.set(FeatureFlags.enablePowerBarSearch, false);

export const useSettings = <TFeatureFlag extends FeatureFlags> (featureFlags: TFeatureFlag[]): { [K in TFeatureFlag]: boolean } => {
    return featureFlags.reduce((acc, flag) => {
        acc[flag] = featureFlagsDefaultValue.get(flag) ?? false;
        return acc;
    }, {} as { [K in TFeatureFlag]: boolean });
};