import React from 'react';
import { BackButton, MainCard } from '@components';
import { useParams } from 'react-router-dom';
import { TextField, IconButton } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { useGetBackgroundMusic, useUploadMusic } from "@hooks";

export const DisplayName = 'Background Musics';
interface HandleMusicUploadEvent extends React.ChangeEvent<HTMLInputElement> { }

export const App = () => {
    const routeParams = useParams();
    const [audioFile, setAudioFile] = React.useState<File | null>(null);
    const [name, setName] = React.useState<string>("");
    const { isLoading, backgroundMusics } = useGetBackgroundMusic();
    const { isLoading: isUploading, uploadMusic } = useUploadMusic();

    const handleNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);
    const handleMusicUpload = React.useCallback((e: HandleMusicUploadEvent) => {
        const file: File | null = e.target.files ? e.target.files[0] : null;
        if (file) {
            setAudioFile(file);
        }
    }, []);

    const handleUploadPress = React.useCallback(() => {
            uploadMusic(name, audioFile).then(() => {
                setName("");
                setAudioFile(null);
            }).catch(() => {});
    }, [audioFile, name, uploadMusic]);

    const isDisabled = !audioFile || !name || isUploading;

    return <MainCard title={DisplayName} secondary={<BackButton />}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '3px', justifyContent: 'space-between' }}>
            <TextField
                label="Name"
                style={{ width: '60%' }}
                value={name}
                onChange={handleNameChange}
            />
            <input type="file" onChange={handleMusicUpload} accept="audio/mp3"
            />
            <IconButton onClick={handleUploadPress} disabled={isDisabled}><Upload /></IconButton>
        </div>
        {/* TODO: add search audio link https://pixabay.com/sound-effects/ */}
        {isLoading && <div>Loading...</div>}
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
            {backgroundMusics.map((music) => {
                return <div key={music.name} style={{ display: 'flex', flexDirection: 'row', gap: '3px', border: '1px', borderStyle: 'solid', padding: '5px', marginBottom: '10px' }}>
                    <span style={{width: '100px'}}>{music.name}</span>
                    <audio controls>
                        <source src={music.url} type="audio/mp3" />
                    </audio>
                </div>;
            })}
        </div>
    </MainCard>;
};

export const params = [];
export const name = 'uploadBackgroundMusic';
export const open = (queryParams?: {}) => {
    return `/sub-apps/uploadBackgroundMusic`;
};
